/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IdentityManager,
  IdentityManagerInterface,
} from "../IdentityManager";

const _abi = [
  {
    inputs: [],
    name: "CannotOverwriteRoot",
    type: "error",
  },
  {
    inputs: [],
    name: "ECDSAInvalidSignature",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "length",
        type: "uint256",
      },
    ],
    name: "ECDSAInvalidSignatureLength",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32",
      },
    ],
    name: "ECDSAInvalidSignatureS",
    type: "error",
  },
  {
    inputs: [],
    name: "ExpiredRoot",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidInitialization",
    type: "error",
  },
  {
    inputs: [],
    name: "NoRootsSeen",
    type: "error",
  },
  {
    inputs: [],
    name: "NotInitializing",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "depth",
        type: "uint8",
      },
    ],
    name: "UnsupportedTreeDepth",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "version",
        type: "uint64",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "root",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint128",
        name: "timestamp",
        type: "uint128",
      },
    ],
    name: "RootAdded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "newExpiry",
        type: "uint256",
      },
    ],
    name: "RootHistoryExpirySet",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "prevRoot",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "postRoot",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "replacedAt",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "latestRoot",
        type: "uint256",
      },
    ],
    name: "SignedRootTransited",
    type: "event",
  },
  {
    inputs: [],
    name: "P",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "treeDepth_",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "semaphoreVerifier_",
        type: "address",
      },
      {
        internalType: "address",
        name: "signer_",
        type: "address",
      },
      {
        internalType: "address",
        name: "sourceStateContract_",
        type: "address",
      },
      {
        internalType: "string",
        name: "chainName_",
        type: "string",
      },
    ],
    name: "__IdentityManager_init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "signer_",
        type: "address",
      },
      {
        internalType: "address",
        name: "facade_",
        type: "address",
      },
      {
        internalType: "string",
        name: "chainName_",
        type: "string",
      },
    ],
    name: "__Signers_init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "chainName",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "methodId_",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "contractAddress_",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "signHash_",
        type: "bytes32",
      },
      {
        internalType: "bytes",
        name: "signature_",
        type: "bytes",
      },
    ],
    name: "checkSignatureAndIncrementNonce",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "facade",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "root_",
        type: "uint256",
      },
    ],
    name: "getRootInfo",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "replacedBy",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "replacedAt",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "isLatest",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "isValid",
            type: "bool",
          },
        ],
        internalType: "struct IIdentityManager.RootInfo",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "methodId_",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "contractAddress_",
        type: "address",
      },
    ],
    name: "getSigComponents",
    outputs: [
      {
        internalType: "string",
        name: "chainName_",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "nonce_",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getTreeDepth",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "root",
        type: "uint256",
      },
    ],
    name: "isLatestRoot",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "root",
        type: "uint256",
      },
    ],
    name: "isValidRoot",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "latestRoot",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    name: "nonces",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "root_",
        type: "uint256",
      },
    ],
    name: "rootExists",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "rootHistory",
    outputs: [
      {
        internalType: "uint128",
        name: "",
        type: "uint128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "rootHistoryExpiry",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "expiryTime_",
        type: "uint256",
      },
    ],
    name: "setRootHistoryExpiry",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "prevRoot_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "postRoot_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "replacedAt_",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "proof_",
        type: "bytes",
      },
    ],
    name: "signedTransitRoot",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "signer",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "sourceStateContract",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "methodId_",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "contractAddress_",
        type: "address",
      },
      {
        internalType: "address",
        name: "newAddress_",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "signature_",
        type: "bytes",
      },
    ],
    name: "validateChangeAddressSignature",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "root",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "signalHash",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "nullifierHash",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "externalNullifierHash",
        type: "uint256",
      },
      {
        internalType: "uint256[8]",
        name: "proof",
        type: "uint256[8]",
      },
    ],
    name: "verifyProof",
    outputs: [],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class IdentityManager__factory {
  static readonly abi = _abi;
  static createInterface(): IdentityManagerInterface {
    return new utils.Interface(_abi) as IdentityManagerInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IdentityManager {
    return new Contract(address, _abi, signerOrProvider) as IdentityManager;
  }
}
